<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/logo-horizontal.png" alt="Travelex UK" />

            <div class="title-text-container">
                <span class="title-text">Your currency expert</span>
            </div>
        </div>
        <div class="top-banner">
            <img src="../../../assets/Branch Services_1600x720.jpg" alt="Travelex UK" />
            <div class="top-banner-curved-edge"></div>
        </div>

        <div class="form-content">
            <div class="bc-panel-body-grid">
                <div class="bc-layout-grid">
                    <div class="section-header">
                        <span class="section-header-text">Select Currency and Amount to collect</span>
                        <div>
                            <mat-icon>currency_exchange</mat-icon>
                        </div>
                    </div>

                    <div class="currency-field-layout">
                        <div class="currency-field-input">
                            <mat-form-field>
                                <mat-label>Currency</mat-label>
                                <span matPrefix><span [class]="flagStyle"></span></span>
                                <mat-select [(value)]="quoteCurrency" (selectionChange)="updateBaseCurrency()">
                                  <mat-option value="AUD"><div class="flag-style"><span class="fi fi-au"></span> AUD</div></mat-option>
                                  <mat-option value="CAD"><div class="flag-style"><span class="fi fi-ca"></span> CAD</div></mat-option>
                                  <mat-option value="EUR"><div class="flag-style"><span class="fi fi-eu"></span> EUR</div></mat-option>
                                  <!-- <mat-option value="GBP"><div class="flag-style"><span class="fi fi-gb"></span> GBP</div></mat-option> -->
                                  <mat-option value="USD"><div class="flag-style"><span class="fi fi-us"></span> USD</div></mat-option>
                                </mat-select>
                                <mat-hint>What you get</mat-hint>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Amount</mat-label>
                                <mat-select [(value)]="quoteCurrencyAmount" (selectionChange)="updateBaseAmount()">
                                    <mat-option *ngFor="let option of amountOptions" [value]="option">{{option}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="coversion-content">
                        <div class="conversion-icon">
                            <mat-icon>double_arrow</mat-icon>
                        </div>

                        <div class="rates-display">
                            <h3 class="rates-text">1 {{baseCurrency}} = {{rate | number: '1.2-2'}} {{quoteCurrency}}</h3>
                        </div>

                        <div class="conversion-icon">
                            <mat-icon>double_arrow</mat-icon>
                        </div>
                    </div>

                    <div class="currency-field-input">
                        <mat-form-field appearance="outline">
                            <mat-label>Currency</mat-label>
                            <input matInput class="input-style" type="text" value="{{baseCurrency}}" readonly />
                            <span matPrefix><span class="flag-style fi fi-gb"></span></span>
                            <mat-hint>What you pay</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Amount</mat-label>
                            <input matInput class="input-style" type="text" min="1" value="{{baseCurrencyAmount | number: '1.2-2'}}" readonly />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="bc-panel-body-grid">
                <div class="bc-layout-grid">
                    <div class="section-header">
                        <span class="section-header-text">Enter your email address</span>
                        <div>
                            <mat-icon>person</mat-icon>
                        </div>
                    </div>
                    <mat-form-field>
                        <mat-label>Email address</mat-label>
                        <input matInput [formControl]="emailControl" placeholder="john.smith@example.com">
                        @if (emailControl.invalid) {
                            <mat-error>You must enter a valid email address</mat-error>
                        }
                    </mat-form-field>
                </div>
            </div>

            <div class="bc-panel-body-grid">
                <div class="bc-layout-grid">
                    <div class="pay-container">
                        <button class="pay-btn" mat-raised-button color="custom-color" [class.spinner]="payloading" [disabled]="this.emailControl.invalid"
                            (click)="payOrder()">
                            PAY
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>